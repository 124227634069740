export const OK_STATUS_201 = 201
export const ERROR_AUTH = 401

export const AUTHORITIES = [
  'SUPER_ADMIN',
  'CITY_ADMIN',
  'WHITE_LABEL_ADMIN',
  'WHITE_LABEL_CITY_ADMIN',
]

export const CLIENTS_CONTENT = 'CLIENTS_CONTENT'
export const BIKE_CONTENT = 'BIKE_CONTENT'
export const TRANSACTION_CONTENT = 'TRANSACTION_CONTENT'
export const STATION_CONTAINER = 'STATION_CONTAINER'
export const PROGRESS_CONTAINER = 'PROGRESS_CONTAINER'
export const LOCKS_CONTAINER = 'LOCKS_CONTAINER'
export const DOCKS_CONTAINER = 'DOCKS_CONTAINER'
export const CITIES_CONTAINER = 'CITIES_CONTAINER'
export const RENTALS_CONTAINER = 'RENTALS_CONTAINER'

export const ACTIVE_STATUS = 'ACTIVE'
export const PAUSE_STATUS = 'PAUSE'
export const FINISHED_STATUS = 'FINISHED'
export const WALLET_DUE = 'WALLET_DUE'

export const NOK_CURRENCY = 'NOK'
export const NOK_SYMBOL = 'kr'
export const USD_CURRENCY = 'USD'
export const USD_SYMBOL = '$'

export const DESC = 'desc'
export const ASC = 'asc'

export const NEW_CITY = 'new city'
export const UPDATE_CITY = 'update city'

export const DOCK_TYPE = 'DOCK_TYPE'
export const USER_TYPE = 'USER_TYPE'

export const STATUS = 'STATUS'
export const FROM_TABLE = 'FROM_TABLE'

export const VIRTUAL_STATION = 'VIRTUAL'
export const CHARGE_STATION = 'CHARGE'

export const DEFAULT_PAGE_SIZE = 10
export const BIKE_HISTORY_PAGE_SIZE = 500
export const PAGINATED_BIKE_HISTORY_PAGE_SIZE = 20
export const BIKE_HISTORY_MONTH = 1
export const BIKE_HISTORY_DAY = 1
export const PAGE_SIZES = [10, 15, 20, 100, 500, 1000]

export const BIKE = 'BIKE'
export const CARGO = 'CARGO'
export const SCOOTER = 'SCOOTER'
export const E_SCOOTER = 'E_SCOOTER'
export const E_BIKE = 'E_BIKE'
export const OMNI_BIKE = 'OMNI'
export const HONGJI_BIKE = 'HONGJI'
export const ACTON_OMNI_BIKE = 'ACTON_OMNI'
export const RIDEMOVI_BIKE = 'RIDEMOVI'
export const SECURINBOX = 'SECURINBOX'

export const STATIONS = 'Stations'
export const BIKES = 'Bikes'

export const itemsTypes = [BIKES, STATIONS]
export const stationStates = ['ONLINE', 'OFFLINE']
export const stationTypes = ['CHARGE', 'VIRTUAL', 'DUCKT']
export const stationVehicleStatuses = [
  'READY',
  'ONLINE',
  'OFFLINE',
  'IN_STATION',
  'RENTING',
  'BROKEN_WORKSHOP',
  'UNKNOWN',
  'LOST',
  'PICKUP_REQUIRED',
  'RETIRED',
  'MISSING',
]
export const bikesStates = [
  'Renting unlocked',
  'Renting locked',
  'Not renting charging',
  'Not renting locked',
  'Not renting unlocked',
  'Low battery or offline',
]
export const colors = ['green', 'lightGreen', 'blue', 'orange', 'yellow', 'red', 'red']
export const stationColors = ['blue', 'red']

export const DATE_FORMAT_DEFAULT = 'YYYY-MM-DDTHH:mm'
export const DATE_FORMAT = 'DD-MM-YYYY HH:mm'

export const ACTIVE = 'ACTIVE'
export const INACTIVE = 'INACTIVE'
export const DELETED = 'DELETED'
export const EXPIRED = 'EXPIRED'
export const SUBSCRIBED = 'SUBSCRIBED'
export const UNSUBSCRIBED = 'UNSUBSCRIBED'
export const PAY_AS_YOU_GO = 'PAY_AS_YOU_GO'
export const EMAIL = 'EMAIL'
export const PASSWORD = 'PASSWORD'
export const CITYID = 'CITYID'
export const WHITELABELID = 'WHITELABELID'

export const TAG_COLOR = {
  RED: 'RED',
  MAGENTA: 'MAGENTA',
  GREEN: 'GREEN',
}

export const WHITELABEL_IDS = {
  MOBY: '1',
  ESB: '2',
  SHAREBIKE: '0',
  BIGISSUE: '3',
  FARTE: '4',
  SIGO: '63',
}

export const WHITELABEL_CODES = {
  MOBY: 'moby',
  ESB: 'esb',
  SHAREBIKE: '',
  BIGISSUE: 'bigIssue',
  FARTE: 'farteBysykkel',
  SIGO: 'sigo',
}

export const limitZoneStatusConst = ['ONLINE', 'OFFLINE']

export const pricingPlanInterval = [
  { label: 'Per/ minute', value: 'MINUTE' },
  { label: 'Per/ hour', value: 'HOURLY' },
  { label: 'Per/ month', value: 'MONTHLY' },
]

export const saveOptions = [
  { label: 'Now', value: 'now' },
  { label: 'Later', value: 'later' },
]

export const planType = [
  {
    value: 'PAY_AS_YOU_GO',
    label: 'PAY_AS_YOU_GO',
  },
  {
    value: 'SUBSCRIPTION',
    label: 'SUBSCRIPTION',
  },
  {
    value: 'BUSINESS',
    label: 'BUSINESS',
  },
]

export const subscriptionPeriod = [
  {
    value: 'MONTHLY',
    label: 'Per/ month',
  },
  {
    value: 'YEARLY',
    label: 'Per/ year',
  },
  // {
  //   value: 'SEASON',
  //   label: 'Per/ season',
  // },
  // {
  //   value: 'ONE_TIME',
  //   label: 'One time',
  // },
]

export const groupOptions = [
  { label: 'BIKE', value: 'BIKE' },
  { label: 'E_BIKE', value: 'E_BIKE' },
  { label: 'SCOOTER', value: 'SCOOTER' },
]

export const config = { label: 'label', value: 'value' }

export const SUBSCRIPTION = 'SUBSCRIPTION'
export const BUSINESS = 'BUSINESS'

export const CONVERT_TYPE = {
  CENT: 'cent',
}

export const couponType = [
  { label: 'ONE_TIME', value: 'ONE_TIME' },
  { label: 'RECURRENT', value: 'RECURRENT' },
  { label: 'DYNAMIC', value: 'DYNAMIC' },
]

export const RECURRENT = 'RECURRENT'
